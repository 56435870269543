const uploadSettings = {
  state: {
    uploadSettings: {
      isSetMaxSize: false,
      compressSize: 200
    }
  },
  mutations: {
    UPLOAD_SETTINGS_LOGOUT(state) {
      state.uploadSettings.isSetMaxSize = false
      state.uploadSettings.compressSize = 200
    }
  },
  actions: {},
  getters: {
    getUploadSettings: (state) => state.uploadSettings
  }
}

export default uploadSettings
