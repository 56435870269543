import * as qiniu from "qiniu-js";
import {
  getUploadToken
} from "@/api/pic"


function getDate() {
  var nowDate = new Date();
  var year = nowDate.getFullYear();
  var month = nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1;
  var day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
  var dateStr = year + "" + month + "" + day;
  return dateStr
}
export function upload(file, fileName) { //file是选择的文件对象
  var managedName = fileName ? fileName : (Math.random(1000) + "-" + file.name)
  const domain = "http://qiniu.chenlj.top/"
  return new Promise((resolve, reject) => {
    getUploadToken().then((res) => { //这是我封装的获取uptoken的方法，自行修改
      let uptoken = res.data;
      let key = getDate() + "/" + managedName; //这是上传后返回的文件名，这里为了避免重复，加上了时间戳和随机数
      var config = {
        useCdnDomain: true, //表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
        region: qiniu.region.z0, //选择上传域名区域；当为 null 或 undefined 时，自动分析上传域名区域。  我这里是华东区
        domain: domain, //配置好的七牛云域名  如   https://cdn.qniyun.com/
        chunkSize: 100, //每个分片的大小，单位mb，默认值3
        forceDirect: true //直传还是断点续传方式，true为直传
      };
      var putExtra = {
        fname: file.name, //文件原始文件名
        params: {},
        mimeType: [] || null
        // ...config,
      };
      var observable = qiniu.upload(file, key, uptoken, putExtra, config);
      observable.subscribe({
        next: (result) => {
          //主要用来展示进度
          console.log("上传进度", result)
        },
        error: (err) => {
          //上传错误后触发
          console.log(err);
          reject(err)
        },
        complete: (result) => {
          //上传成功后触发。包含文件地址。
          console.log("result", result);
          let data = {
            url: domain + result.key,
            key: result.key
          }
          resolve(data)
        },
      });
    });
  })
}
