<template>
  <div class="menu">
    <ul class="nav-list">
      <li
        class="nav-item flex-center"
        v-for="(navItem, index) in navList"
        :key="index"
        :class="{ active: navItem.path === activeMenu }"
        @click="clickToMenu(navItem.path)"
      >
        <div class="nav-content">
          <a-icon :type="navItem.icon" />
          <!-- <i class="nav-icon" :class="navItem.icon"></i> -->
          <span class="nav-name">{{ navItem.name }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'sider',
  data() {
    return {
      activeMenu: '',
      navList: [
        {
          name: '图床配置',
          icon: 'form',
          isActive: false,
          path: '/setting',
        },
        {
          name: '上传图片',
          icon: 'upload',
          isActive: true,
          path: '/upload',
        },
        {
          name: '抓取图片',
          icon: 'fork',
          isActive: true,
          path: '/fetch',
        },
        {
          name: '图床管理',
          icon: 'bars',
          isActive: false,
          path: '/picture',
        },
      ],
    }
  },
  watch: {
    $route() {
      this.renewActiveMenu()
    },
  },
  created() {
    this.renewActiveMenu()
    console.log('this.$route.path', this.$route.path)
  },
  methods: {
    renewActiveMenu() {
      this.activeMenu = this.$route.path
      // console.log('path', this.$route.path)
      if (this.activeMenu == '/') {
        this.activeMenu = '/upload'
      } else if (this.activeMenu == '/index') {
        this.activeMenu = '/upload'
      }
    },
    clickToMenu(path) {
      if (this.activeMenu !== path) {
        this.activeMenu = path
        this.$router.push(path)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: #fff;

  ul.nav-list {
    padding: 0;
    margin: 0;

    li.nav-item {
      box-sizing: border-box;
      width: 100%;
      height: 68px;
      cursor: pointer;

      &.active {
        font-weight: bold;
        background-color: #f0f0f0;
      }
      .nav-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .nav-icon {
          font-size: 26px;
        }

        .nav-name {
          margin-top: 2px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
