const imageViewer = {
  state: {
    imageViewer: {
      url: '',
      isShow: false
    }
  },
  mutations: {
    IMAGE_VIEWER(state, {
      url,
      isShow
    }) {
      state.imageViewer.url = url
      state.imageViewer.isShow = isShow
    },

    IMAGE_VIEWER_LOGOUT(state) {
      state.imageViewer.isShow = false
      state.imageViewer.url = ''
    }
  },
  actions: {},
  getters: {
    getImageViewer: (state) => state.imageViewer
  }
}

export default imageViewer
