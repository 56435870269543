// 暂时不知道怎么用的
const getters = {
  user: state => state.user.user,
  userConfigInfo: state => state.userConfigInfo.userConfigInfo,
  toUploadImage: state => state.toUploadImage,
  uploadAreaActive: state => state.uploadAreaActive,
  uploadSettings: state => state.uploadSettings.uploadSettings,
  uploadedImageList: state => state.uploadedImageList.uploadedImageList,
  loggingStatus: state => state.userConfigInfo.userConfigInfo.loggingStatus,
  imageViewer: state => state.imageViewer.imageViewer,
}
export default getters
