<template>
  <div>
    <a-spin :spinning="imageObj.deleting" tip="删除中...">
      <div class="image-card">
        <div class="image-box">
          <img :src="imageObj.urlLink" @click="imageView(imageObj)" />
        </div>
        <div class="info-box">
          <div class="image-info">
            <div class="filename">{{ imageObj.name }}</div>
            <div class="image-operation">
              <div class="delete">
                <a-popover content="删除" placement="top">
                  <a-icon type="delete" @click="deleteImage(imageObj)" />
                </a-popover>
              </div>
              <div>
                <CopyExternalLink :img-obj="imageObj"></CopyExternalLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-spin>
  </div>
</template>
<script>
import store from '@/store'
import CopyExternalLink from './CopyExternalLink'
export default {
  name: 'ImageCard',

  components: {
    CopyExternalLink,
  },
  props: {
    imageObj: {
      type: Object,
      default: () => {},
    },
    isUploaded: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    imageView(imgObj) {
      console.log('imgObj', imgObj)
      store.commit('IMAGE_VIEWER', {
        isShow: true,
        url: imgObj.urlLink,
      })
    },
    deleteImage(imageObj) {
      let that = this
      this.$confirm({
        centered: true,
        title: '确定返回?',
        content: '您将有可能丢失所有未保存的变更',
        okText: '是',
        okType: 'danger',
        cancelText: '否',
        onOk() {
          console.log('OK')
          // that.$router.go(-1)
          imageObj.deleting = true
          let flag = true
          if (flag) {
            imageObj.deleting = false
            that.$message.success('删除成功！')
            store.dispatch('UPLOADED_LIST_REMOVE', imageObj)
            return
          }
        },
        onCancel() {
          console.log('Cancel')
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.image-card {
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: 1px 2px 3px #ccc;
  box-sizing: border-box;
  padding-bottom: 56px;

  .image-box {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .info-box {
    width: 100%;
    height: 56px;
    position: absolute;
    bottom: 0;
    left: 0;

    .image-info {
      width: 100%;
      height: 100%;
      padding: 5px;
      color: #2c3e50;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .filename {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px;
      }

      .image-operation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 5px;

        .delete {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          i {
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>
