<template>
  <div class="copy-external-link-box">
    <a-popover content="点击复制 url 外链" placement="top">
      <span class="btn-item copy-url" @click="copyExternalLink('url')">URL</span>
    </a-popover>
    <a-popover content="点击复制 html 外链" placement="top">
      <span class="btn-item copy-url" @click="copyExternalLink('html')">HTML</span>
    </a-popover>
    <a-popover content="点击复制 markdown 外链" placement="top">
      <span class="btn-item copy-url" @click="copyExternalLink('md')">MD</span>
    </a-popover>
  </div>
</template>

<script>
export default {
  name: 'CopyExternalLink',
  props: {
    imgObj: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    copyExternalLink(type) {
      let externalLink = ''
      let successInfo = ''
      switch (type) {
        case 'url':
          externalLink = this.imgObj.urlLink
          successInfo = 'url'
          break
        case 'html':
          externalLink = this.imgObj.htmlLink
          successInfo = 'html'
          break
        case 'md':
          externalLink = this.imgObj.mdLink
          successInfo = 'markdown'
          break
      }
      //点击复制
      let externalLinkDom = document.querySelector('.temp-external-link')
      if (!externalLinkDom) {
        externalLinkDom = document.createElement('textarea')
        externalLinkDom.setAttribute('class', 'temp-external-link')
        externalLinkDom.style.position = 'absolute'
        externalLinkDom.style.top = '-99999px'
        externalLinkDom.style.left = '-99999px'
        document.body.appendChild(externalLinkDom)
      }

      externalLinkDom.value = externalLink
      externalLinkDom.select()
      document.execCommand('copy')
      this.$message.success(`${successInfo} 外链复制成功！`)
    },
  },
}
</script>

<style lang="scss" scoped>
.copy-external-link-box {
  .btn-item {
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 12px;
    margin-right: 6px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:last-child {
      margin-left: 0;
    }
  }

  .transform-btn {
    border: 1px solid #2c3e50;
    color: #2c3e50;
    padding: 0 1px 0 5px;

    &.is-transform {
      background: #2c3e50;
      color: #fff;
    }
  }

  .copy-url {
    padding: 0 2px;
    border: 1px solid #2c3e50;
    color: #2c3e50;

    &:hover {
      background: #2c3e50;
      color: #fff;
    }
  }
}
</style>
