<template>
  <div>
    <a-spin :spinning="imageLoading" tip="图片上传中...">
      <div
        class="upload-area active-upload"
        :class="{ focus: uploadAreaActive }"
        @dragover.prevent
        @drop.stop.prevent="onDrop"
        @paste="onPaste"
      >
        <label for="uploader" class="active-upload" v-if="uploadAreaActive"></label>
        <input id="uploader" type="file" @change="onSelect" multiple="multiple" />
        <div class="tips active-upload" v-if="!toUploadImage.curImgBase64Url">
          <i class="icon el-icon-upload active-upload"></i>
          <div class="text active-upload">拖拽、粘贴、或点击此处上传</div>
        </div>
        <img
          class="active-upload"
          v-if="toUploadImage.curImgBase64Url"
          :src="toUploadImage.curImgBase64Url"
          alt="Pictures to be uploaded"
        />
      </div>
    </a-spin>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '@/store'
import { filenameHandle } from '@/utils/fileUtil'
import chooseImg from '@/utils/chooseImg'
import paste from '@/utils/paste'
import getUuid from '@/utils/uuidUtil'
import { getTimestamp } from '@/utils/timeUtil'

export default {
  name: 'UploadArea',
  props: {
    imageLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['uploadAreaActive', 'uploadSettings', 'toUploadImage']),
  },
  methods: {
    // 选择图片
    onSelect(e) {
      store.commit('CHANGE_UPLOAD_AREA_ACTIVE', true)
      // eslint-disable-next-line no-restricted-syntax
      for (const file of e.target.files) {
        chooseImg(
          file,
          // eslint-disable-next-line no-shadow
          (url, file) => {
            this.getImage(url, file)
          },
          this.uploadSettings.isSetMaxSize ? this.uploadSettings.compressSize * 1024 : null,
        )
      }
    },

    // 拖拽图片
    onDrop(e) {
      const targetFile = e.dataTransfer.files[0]
      chooseImg(
        targetFile,
        (url, file) => {
          this.getImage(url, file)
        },
        this.uploadSettings.isSetMaxSize ? this.uploadSettings.compressSize * 1024 : null,
      )
      store.commit('CHANGE_UPLOAD_AREA_ACTIVE', true)
    },

    // 复制图片
    async onPaste(e) {
      const { url, file } = await paste(
        e,
        this.uploadSettings.isSetMaxSize ? this.uploadSettings.compressSize * 1024 : null,
      )
      this.getImage(url, file)
    },

    // 获取图片对象
    getImage(url, file) {
      if (
        this.toUploadImage.list.length === this.toUploadImage.uploadedNumber &&
        this.toUploadImage.list.length > 0 &&
        this.toUploadImage.uploadedNumber > 0
      ) {
        store.dispatch('TO_UPLOAD_IMAGE_CLEAN_LIST')
        store.dispatch('TO_UPLOAD_IMAGE_CLEAN_UPLOADED_NUMBER')
      }

      const curImg = {
        uuid: '',

        uploadStatus: {
          progress: 0,
          uploading: false,
        },

        imgData: {
          base64Content: '',
          base64Url: '',
        },

        fileInfo: {
          size: 0,
          lastModified: 0,
        },

        filename: {
          name: '',
          hash: '',
          timestamp: '',
          suffix: '',
          now: '',
          initName: '',
          newName: 'xxx',
          isTimestamp: false,
          isRename: true,
        },

        externalLink: {
          urlLink: '',
          htmlLink: '',
          mdLink: '',
        },
      }

      curImg.file = file
      curImg.imgData.base64Url = url
      // eslint-disable-next-line prefer-destructuring
      curImg.imgData.base64Content = url.split(',')[1]

      const { name, suffix } = filenameHandle(file.name)

      const hash = getUuid()
      const timestamp = getTimestamp()
      curImg.uuid = hash

      curImg.fileInfo.size = file.size
      curImg.fileInfo.lastModified = file.lastModified

      curImg.filename.name = hash
      curImg.filename.timestamp = timestamp
      curImg.filename.newName = hash
      curImg.filename.suffix = suffix
      curImg.filename.now = `${hash}.${suffix}`
      curImg.filename.initName = name

      // store.dispatch('TO_UPLOAD_IMAGE_LIST_ADD', JSON.parse(JSON.stringify(curImg)))
      store.dispatch('TO_UPLOAD_IMAGE_LIST_ADD', curImg)
      store.dispatch('TO_UPLOAD_IMAGE_SET_CURRENT', { uuid: hash, base64Url: url })
    },
  },
}
</script>

<style lang="scss" scoped>
.upload-area {
  position: relative;
  width: 100%;
  height: 300px;
  border: 4px dashed #999;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;

  &.focus {
    border-color: #2066bb;
  }

  &:hover {
    border-color: #2066bb;
  }

  label {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1000;
    cursor: pointer;
  }

  input[type='file'] {
    position: absolute;
    left: -9999px;
    top: -9999px;
  }

  .tips {
    text-align: center;
    color: #aaa;

    .icon {
      font-size: 100px;
      margin-bottom: 10px;
    }

    .text {
      cursor: default;
      font-size: 20px;
    }
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
</style>
