import request from '@/utils/request'

export function getUploadToken() {
  return request({
    url: 'pic/uploadToken',
    method: 'post',
  })
}
export function fetchSrc(src) {
  return request({
    url: 'pic/fetch',
    method: 'post',
    params: {
      src
    }
  })
}
export function picWall() {
  return request({
    url: 'pic/picWall',
    method: 'post'
  })
}
export function fileList() {
  return request({
    url: 'pic/list',
    method: 'post'
  })
}
export function deleteFile(fileName) {
  return request({
    url: 'pic/list',
    method: 'post',
    params: {
      fileName
    }
  })
}
