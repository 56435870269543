import CommonLayout from '../layouts/CommonLayout.vue'
// import Index from '../views/index.vue'
import Upload from '../views/upload.vue'
import Picture from '../views/picture.vue'
import Fetch from '../views/fetch.vue'
import Setting from '../views/setting.vue'
import Reditrct from '../views/reditrct.vue'

export default [{
    path: '/login',
    name: 'Login',
    // component: Tool
    component: () => import( /* webpackChunkName: "login" */ '../views/login.vue')
  }, {
    path: '/',
    name: 'CommonLayout',
    redirect: '/index',
    component: CommonLayout,
    children: [{
      path: '/index',
      name: 'Upload',
      component: Upload
    }, {
      path: '/upload',
      name: 'Upload',
      component: Upload
    }, {
      path: '/picture',
      name: 'Picture',
      component: Picture
    }, {
      path: '/fetch',
      name: 'Fetch',
      component: Fetch
    }, {
      path: '/setting',
      name: 'Setting',
      component: Setting
    }]
  },
  {
    path: '/redirect',
    name: 'Redirect',
    component: Reditrct,
  },
  {
    path: '*',
    redirect: '/upload',
  }

]
