module.exports = {
  frontTitle: 'pic-bed',
  /**
   * @description token key
   */
  TokenKey: 'PIC-BED-TOEKN',
  tokenCookieExpires: 365,
  caseNumber: '闽ICP备XXXXXXXX号',
  footerTxt: 'PicBed ©2021 Created by chenlj',

}
