<template>
  <div>
    <div class="header navbar">
      <div class="header-left">
        <div class="brand" @click="router.push('/')">
          <div class="logo">
            <img src="../assets/logo.png" alt="PicBed" />
          </div>
          <div class="title">PicBed</div>
        </div>
      </div>

      <div class="header-right">
        <div class="user-info" @click="onUserInfoClick">
          <div class="username">
            {{ userConfigInfo.owner }}
          </div>

          <!-- <div class="avatar" v-if="!userConfigInfo?.avatarUrl">
          <i class="el-icon-user-solid"></i>
        </div>

        <el-dropdown trigger="click" @command="handleCommand" v-if="userConfigInfo?.avatarUrl">
          <span class="el-dropdown-link">
            <span class="avatar">
              <img :src="userConfigInfo?.avatarUrl" :alt="userConfigInfo?.owner" />
            </span>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'haeder',
  data() {
    return {
      userConfigInfo: { avatarUrl: '', owner: '' },
    }
  },
  methods: {
    onUserInfoClick() {},
  },
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 64px;
  overflow: hidden;
  position: relative;
  background: #fff;
  padding-right: 12px;
  padding-left: 24px;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  .icon {
    float: left;
  }
  .right-menu {
    float: right;
    height: 100%;
    line-height: 65px;

    &:focus {
      outline: none;
    }
  }
}
.header {
  width: 100%;
  background: #fff;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .header-left {
    height: 100%;
    display: flex;
    justify-content: flex-start;

    .brand {
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;

      .logo {
        width: 46px;
        height: 46px;
        margin-right: 10px;

        img {
          vertical-align: baseline;
          width: 100%;
        }
      }

      .title {
        font-size: 36px;
        font-weight: bold;
      }
    }

    .website-count {
      box-sizing: border-box;
      display: flex;
      align-items: flex-end;
      font-size: 14px;
      margin-left: 10px;
      padding-bottom: 12px;
      cursor: pointer;
    }
  }

  .header-right {
    .user-info {
      display: flex;
      align-items: center;
      cursor: pointer;

      .username {
        font-size: 16px;
      }

      .avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 38px;
        height: 38px;
        color: #2c3e50;
        border-radius: 50%;
        border: 1px solid #2c3e50;
        margin-left: 10px;
        padding: 1px;
        box-sizing: border-box;

        i {
          font-size: 28px;
        }

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
    }
  }
}
</style>
