import defaultSettings from '@/settings'
const {
  title,
  frontTitle,
  footerTxt,
  caseNumber
} = defaultSettings
const state = {
  title: title,
  frontTitle: frontTitle,
  footerTxt: footerTxt,
  caseNumber: caseNumber
}


export default {
  namespaced: true,
  state,
}
