const initUploadedImageList = () => {
  const imageList = sessionStorage.getItem('PICX_UPLOADED')
  return imageList ? JSON.parse(imageList) : []
}

const uploadedImageList = {
  state: {
    uploadedImageList: initUploadedImageList()
  },

  mutations: {},

  actions: {
    // 上传完成的图片列表 - 增加
    UPLOADED_LIST_ADD({
      state,
      dispatch
    }, item) {
      state.uploadedImageList.unshift(item)
      dispatch('UPLOADED_LIST_PERSIST')
    },

    // 上传完成的图片列表 - 删除
    UPLOADED_LIST_REMOVE({
      state,
      dispatch
    }, item) {
      if (state.uploadedImageList.length > 0) {
        const rmIndex = state.uploadedImageList.findIndex((v) => v.uuid === item.uuid)
        if (rmIndex !== -1) {
          state.uploadedImageList.splice(rmIndex, 1)
          dispatch('UPLOADED_LIST_PERSIST')
        }
      }
    },

    // 上传完成的图片列表 - 持久化
    UPLOADED_LIST_PERSIST({
      state
    }) {
      sessionStorage.setItem('PICX_UPLOADED', JSON.stringify(state.uploadedImageList))
    },

    // 上传完成的图片列表 - 退出登录
    UPLOADED_LIST_LOGOUT({
      state
    }) {
      state.uploadedImageList = []
      sessionStorage.removeItem('PICX_UPLOADED')
    }
  },

  getters: {
    getUploadedImageList: (state) => state.uploadedImageList
  }
}

export default uploadedImageList
