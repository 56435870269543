<template>
  <div class="selected-info-bar-box" v-if="userConfigInfo.selectedRepos">
    <span class="info-item">
      仓库：
      <a-tag size="mini">{{ userConfigInfo.selectedRepos }}</a-tag>
    </span>
    <span class="info-item" v-if="userConfigInfo.selectedBranch">
      分支：
      <a-tag size="mini">{{ userConfigInfo.selectedBranch }}</a-tag>
    </span>
    <span class="info-item" v-if="userConfigInfo.selectedDir">
      目录：
      <a-tag size="mini">{{ userConfigInfo.selectedDir }}</a-tag>
    </span>
  </div>
</template>
<script>
export default {
  name: 'SelectedInfoBar',
  data() {
    return {
      userConfigInfo: {
        selectedRepos: 'selectedRepos',
        selectedBranch: 'selectedBranch',
        selectedDir: 'selectedDir',
      },
    }
  },
}
</script>
<style lang="scss" scoped>
.selected-info-bar-box {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  box-sizing: border-box;

  .info-item {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
