<template>
  <div class="page-container management-page-container">
    <div class="content-container">
      <div class="top">
        <div class="left">
          <SelectedInfoBar></SelectedInfoBar>
        </div>

        <div class="right">
          切换目录：
          <!-- <el-select v-model="userConfigInfo.selectedDir" placeholder="请选择" size="mini" @change="dirChange">
            <el-option
              v-for="(item, index) in userConfigInfo.dirList"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select> -->
        </div>
      </div>
      <div class="bottom">
        <a-spin :spinning="loadingImageList" tip="加载中...">
          <ul class="image-list">
            <li
              class="image-item"
              v-for="(image, index) in currentDirImageList"
              :key="index"
              :style="{
                width: '220px',
                height: '230px',
              }"
            >
              <ImageCard :image-obj="image" />
            </li>
          </ul>
        </a-spin>
      </div>
    </div>
  </div>
</template>

<script>
import { picWall } from '@/api/pic'
import { mapGetters } from 'vuex'
import ImageCard from '@/components/ImageCard'
import SelectedInfoBar from '@/components/SelectedInfoBar'
export default {
  components: {
    ImageCard,
    SelectedInfoBar,
  },
  data() {
    return {
      loadingImageList: false,
      currentDirImageList: [],
    }
  },
  computed: {
    ...mapGetters(['userConfigInfo', 'loggingStatus']),
  },
  created() {
    this.getPicWall()
  },
  methods: {
    getPicWall() {
      this.loadingImageList = true
      picWall().then(res => {
        this.currentDirImageList = res.data
        this.loadingImageList = false
        console.log('loadingImageList', this.loadingImageList)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.management-page-container {
  .content-container {
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 50px;
    box-sizing: border-box;

    .top {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      padding-bottom: 20px;
    }

    .bottom {
      min-height: 230px;
      width: 100%;
      height: 100%;
      box-sizing: border-box;

      .image-list {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        list-style: none;
        border: 1px solid #ddd;
        overflow-y: auto;
        box-sizing: border-box;

        li.image-item {
          float: left;
          box-sizing: border-box;
          padding: 10px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.page-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 30px;
  background: #fff;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  overflow-y: auto;
}
</style>
