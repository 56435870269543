<template>
  <div class="commonLayout">
    <a-layout class="app-layout">
      <a-layout-header class="app-header">
        <Header></Header>
      </a-layout-header>
      <a-layout class="app-middle">
        <a-layout-sider class="app-sider" width="80px">
          <Sider></Sider>
        </a-layout-sider>
        <a-layout-content class="app-content">
          <router-view />
        </a-layout-content>
      </a-layout>
      <hr />
      <a-layout-footer class="app-footer">
        <a href="https://beian.miit.gov.cn/" target="_blank">{{ $store.state.settings.caseNumber }}</a>
        <span class="space">⋅</span>
        <span v-html="$store.state.settings.footerTxt" />
      </a-layout-footer>
      <a-back-top />
    </a-layout>
    <ImageViewer></ImageViewer>
  </div>
</template>

<script>
import Header from '@/layouts/Header'
import Sider from '@/layouts/Sider'
import ImageViewer from '@/components/ImageViewer'
export default {
  name: 'app',
  components: {
    Header,
    Sider,
    ImageViewer,
  },
}
</script>

<style lang="scss" scoped>
.commonLayout {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: #eff3f5;
  .app-header {
    padding: 0 20px;
    background-color: #fff;
  }
  .ant-layout-sider {
    background-color: #fff;
  }
  .app-middle {
    min-height: calc(100vh - 132px);
    padding-top: 16px;
    .app-content {
      background-color: #fff;
      margin-left: 16px;
    }
  }

  hr {
    width: 80%;
    margin: 10px auto;
    height: 1px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), cyan, rgba(0, 0, 0, 0));
  }
  .app-footer {
    height: 40px;
    line-height: 40px;
    text-align: center;
    padding: 0;
    // color: #fff;
    font-family: Arial, serif;
    font-size: 12px;
    letter-spacing: 1px;
    .space {
      padding: 0 10px;
    }
  }
}
</style>
