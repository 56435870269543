const initUserConfigInfo = () => {
  const config = localStorage.getItem('PICX_CONFIG')
  return config ?
    JSON.parse(config) :
    {
      token: '123',
      owner: '',
      email: '',
      name: '',
      avatarUrl: '',
      selectedRepos: '111',
      reposList: [],
      selectedBranch: '222',
      dirMode: '',
      selectedDir: '123',
      dirList: [],
      loggingStatus: false
    }
}

const userConfigInfo = {
  state: {
    userConfigInfo: initUserConfigInfo()
  },

  actions: {
    // 设置用户配置信息
    SET_USER_CONFIG_INFO({
      state
    }, configInfo) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in configInfo) {
        // eslint-disable-next-line no-prototype-builtins
        if (state.userConfigInfo.hasOwnProperty(key)) {
          // @ts-ignore
          state.userConfigInfo[key] = configInfo[key]
        }
      }
    },

    // 用户配置信息 - 增加目录
    USER_CONFIG_INFO_ADD_DIR({
      state,
      dispatch
    }, dir) {
      if (!state.userConfigInfo.dirList.some((v) => v.value === dir)) {
        state.userConfigInfo.dirList.push({
          label: dir,
          value: dir
        })
        dispatch('USER_CONFIG_INFO_PERSIST')
      }
    },

    // 用户配置信息 - 删除目录列表的某个目录
    USER_CONFIG_INFO_REMOVE_DIR({
      state,
      dispatch
    }, dir) {
      const {
        dirList
      } = state.userConfigInfo
      if (dirList.some((v) => v.value === dir)) {
        const rmIndex = dirList.findIndex((v) => v.value === dir)
        dirList.splice(rmIndex, 1)
        dispatch('USER_CONFIG_INFO_PERSIST')
      }
    },

    // 持久化用户配置信息
    USER_CONFIG_INFO_PERSIST({
      state
    }) {
      state.userConfigInfo.selectedDir = state.userConfigInfo.selectedDir.replace(
        /\s+/g,
        '-'
      )
      localStorage.setItem('PICX_CONFIG', JSON.stringify(state.userConfigInfo))
    },

    // 退出登录
    USER_CONFIG_INFO_LOGOUT({
      state
    }) {
      state.userConfigInfo = {
        token: '',
        owner: '',
        email: '',
        name: '',
        avatarUrl: '',
        selectedRepos: '',
        reposList: [],
        selectedBranch: '',
        dirMode: '',
        selectedDir: '',
        dirList: [],
        loggingStatus: false
      }
      localStorage.removeItem('PICX_CONFIG')
    }
  },

  getters: {
    getUserLoggingStatus: (state) =>
      state.userConfigInfo.loggingStatus,
    getUserConfigInfo: (state) =>
      state.userConfigInfo
  }
}

export default userConfigInfo
