<template>
  <div class="to-upload-image-list-card" v-if="toUploadImage.list.length || userConfigInfo.selectedRepos">
    <div class="header">
      <div>
        <SelectedInfoBar></SelectedInfoBar>
      </div>
      <div>
        <span v-if="toUploadImage.list.length">
          已上传：{{ toUploadImage.uploadedNumber }} / {{ toUploadImage.list.length }}
        </span>
      </div>
    </div>
    <div class="body" v-if="toUploadImage.list.length">
      <ul class="image-uploading-info-box">
        <li
          class="image-uploading-info-item"
          :class="{ disable: loadingAllImage }"
          v-for="(imgItem, index) in toUploadImage.list"
          :key="index"
        >
          <div class="left-image-box flex-center">
            <div class="image-box" @click="imageViewer(imgItem.imgData.base64Url)">
              <img :src="imgItem.imgData.base64Url" />
            </div>
          </div>

          <div class="right-operation-box">
            <div class="top">
              <div class="image-name">
                {{ imgItem.filename.now }}
              </div>

              <div class="image-info">
                <span class="file-size item">
                  {{ getFileSize(imgItem.fileInfo.size) }}
                </span>
                <span class="last-modified item">
                  {{ formatLastModified(imgItem.fileInfo.lastModified) }}
                </span>
              </div>
            </div>

            <div
              class="bottom rename-operation"
              v-if="!imgItem.uploadStatus.uploading && imgItem.uploadStatus.progress !== 100"
            >
              <div class="rename-checkbox">
                <a-checkbox v-model="imgItem.filename.isTimestamp" @change="hashRename($event, imgItem)">
                  哈希化
                </a-checkbox>
              </div>

              <div class="rename-checkbox">
                <a-checkbox v-model="imgItem.filename.isRename" @change="rename($event, imgItem)">重命名</a-checkbox>
              </div>

              <div class="rename-input">
                <a-input
                  v-if="imgItem.filename.isRename"
                  v-model="imgItem.filename.newName"
                  @input="rename($event, imgItem)"
                  clearable
                ></a-input>
              </div>
            </div>

            <div
              class="bottom rename-operation"
              v-if="!imgItem.uploadStatus.uploading && imgItem.uploadStatus.progress === 100"
            >
              <CopyExternalLink :img-obj="imgItem.uploadedImg" />
            </div>
          </div>

          <div
            class="upload-status-box"
            :class="{
              'wait-upload': !imgItem.uploadStatus.uploading && imgItem.uploadStatus.progress !== 100,
              uploading: imgItem.uploadStatus.uploading && imgItem.uploadStatus.progress !== 100,
              uploaded: !imgItem.uploadStatus.uploading && imgItem.uploadStatus.progress === 100,
            }"
          >
            <a-icon type="upload" v-if="!imgItem.uploadStatus.uploading && imgItem.uploadStatus.progress !== 100" />
            <a-icon type="loading" v-if="imgItem.uploadStatus.uploading && imgItem.uploadStatus.progress !== 100" />
            <a-icon type="check" v-if="!imgItem.uploadStatus.uploading && imgItem.uploadStatus.progress === 100" />
          </div>

          <div
            class="remove-to-upload-image"
            v-if="imgItem.uploadStatus.progress !== 100 && !imgItem.uploadStatus.uploading"
            @click="removeToUploadImage(imgItem)"
          >
            <a-popover effect="dark" content="移除" placement="top">
              <!-- <i class="el-icon-delete"></i> -->
              <a-icon type="delete" />
            </a-popover>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { upload } from '@/utils/qiniu'
import { mapGetters } from 'vuex'
import store from '@/store'
import { getFileSize, generateLink } from '@/utils/fileUtil'
import { formatTimestamp } from '@/utils/timeUtil'
import CopyExternalLink from '@/components/CopyExternalLink'
import SelectedInfoBar from '@/components/SelectedInfoBar'
export default {
  name: 'ToUploadImageCard',

  components: {
    CopyExternalLink,
    SelectedInfoBar,
  },

  props: {
    loadingAllImage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['userConfigInfo', 'toUploadImage']),
  },
  methods: {
    // 是否时间戳
    hashRename(e, img) {
      if (e.target.checked) {
        img.filename.now = `${img.filename.name}-${img.filename.timestamp}.${img.filename.suffix}`
      } else {
        img.filename.now = `${img.filename.name}.${img.filename.suffix}`
      }
    },
    // 重命名
    rename(e, img) {
      if (e.target.checked) {
        img.filename.name = img.filename.newName
      } else {
        img.filename.name = img.filename.initName
      }

      if (img.filename.isTimestamp) {
        img.filename.now = `${img.filename.name}-${img.filename.timestamp}.${img.filename.suffix}`
      } else {
        img.filename.now = `${img.filename.name}.${img.filename.suffix}`
      }
    },
    // 获取文件大小
    getFileSize(size) {
      return getFileSize(size)
    },

    formatLastModified(t) {
      return formatTimestamp(t)
    },

    // 上传图片
    async uploadImage_all() {
      const uploadIndex = this.toUploadImage.uploadedNumber
      // TODO UploadStatusEnum
      if (uploadIndex >= this.toUploadImage.list.length) {
        return 'uploaded'
      }
      if ((await this.uploadImage_single(this.toUploadImage.list[uploadIndex])) === true) {
        if (uploadIndex < this.toUploadImage.list.length) {
          await this.uploadImage_all()
          return 'allUploaded'
        }
        return 'uploaded'
      }
      return 'uploadFail'
    },

    uploadImage_single(img) {
      console.log('img', img)
      img.uploadStatus.uploading = true
      return new Promise((resolve, reject) => {
        upload(img.file, img.filename.now)
          .then(res => {
            this.uploadSuccess(res, img)
            console.log('res123', res)
            resolve(true)
          })
          .catch(error => {
            img.uploadStatus.uploading = false
            this.$message.error(error)
            reject(error)
          })
      })
    },
    //上传成功后的外链处理
    uploadSuccess(res, img) {
      // 上传状态处理
      img.uploadStatus.progress = 100
      img.uploadStatus.uploading = false

      // 生成外链
      img.externalLink.url = generateLink(img.filename.now, res.url, 'url')
      img.externalLink.html = generateLink(img.filename.now, res.url, 'html')
      img.externalLink.md = generateLink(img.filename.now, res.url, 'md')

      const item = {
        uuid: img.uuid,
        key: res.key,
        urlLink: img.externalLink.url,
        htmlLink: img.externalLink.html,
        mdLink: img.externalLink.md,
        name: img.filename.now,
        deleting: false,
      }

      img.uploadedImg = item
      // uploadedList 增加图片(本次session上传成功的所有图片)
      store.dispatch('UPLOADED_LIST_ADD', item)

      store.dispatch('TO_UPLOAD_IMAGE_UPLOADED', img.uuid)
    },

    imageViewer(base64Url) {
      console.log('imgObj', base64Url)
      store.commit('IMAGE_VIEWER', {
        isShow: true,
        url: base64Url,
      })
    },

    removeToUploadImage(imgItem) {
      store.dispatch('TO_UPLOAD_IMAGE_LIST_REMOVE', imgItem.uuid)
    },
  },
}
</script>

<style lang="scss" scoped>
.to-upload-image-list-card {
  position: relative;
  width: 100%;
  box-sizing: border-box;

  .header {
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 6px;
  }

  .body {
    width: 100%;
    height: 100%;
    max-height: 170px;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid #ddd;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
    }

    .image-uploading-info-box {
      position: relative;
      width: 100%;
      box-sizing: border-box;
      padding: 0;
      margin: 0;

      .image-uploading-info-item {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        height: 68px;
        border: 1px solid #ddd;
        border-radius: 5px;
        margin-bottom: 10px;
        overflow: hidden;
        font-size: 15px;
        padding-left: 66px;
        transition: all 0.3s ease;

        &.disable {
          pointer-events: none;
          cursor: not-allowed;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          box-shadow: 0 0 5px #ddd;
        }

        .left-image-box {
          position: absolute;
          top: 0;
          left: 0;
          width: 66px;
          height: 100%;
          box-sizing: border-box;
          margin-right: 5px;
          padding: 5px;

          .image-box {
            width: 100%;
            height: 100%;
            border: 1px solid #ddd;
            border-radius: 5px;
            box-sizing: border-box;
            overflow: hidden;
            cursor: pointer;
          }

          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }

        .right-operation-box {
          position: relative;
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          padding: 5px 22px 5px 5px;

          .top,
          .bottom {
            width: 100%;
            height: 50%;
            box-sizing: border-box;
            padding: 0 5px;
          }

          .top {
            display: flex;
            justify-content: space-between;

            .image-name,
            .image-info {
              display: flex;
              align-items: center;
              box-sizing: border-box;
              height: 100%;
            }

            .image-name {
              font-size: 13px;
              width: 55%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .image-info {
              font-size: 12px;
              width: 45%;

              .item {
                padding: 1px 4px;
                background-color: #f1f1f1;
                border-radius: 2px;
                margin-left: 10px;
              }
            }
          }

          .bottom {
            display: flex;
            align-items: center;

            &.rename-operation {
              .rename-checkbox {
                width: 80px;
                margin-right: 10px;
              }

              .rename-input {
                input {
                  height: 23px;
                  line-height: 23px;
                }
              }
            }
          }
        }

        .upload-status-box {
          box-sizing: border-box;
          color: #fff;
          position: absolute;
          right: -17px;
          top: -7px;
          width: 46px;
          height: 26px;
          text-align: center;
          transform: rotate(45deg);
          box-shadow: 0 1px 1px #ddd;

          &.wait-upload {
            background: #e6a23c;
          }

          &.uploading {
            background: #409eff;
          }

          &.uploaded {
            background: #67c23a;
          }

          i {
            font-size: 12px;
            margin-top: 12px;
            transform: rotate(-45deg);
          }
        }

        .remove-to-upload-image {
          position: absolute;
          bottom: 5px;
          right: 5px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
