const uploadAreaActive = {
  state: {
    uploadAreaActive: false
  },
  mutations: {
    // 修改上传区域激活状态
    CHANGE_UPLOAD_AREA_ACTIVE(state, isActive) {
      state.uploadAreaActive = isActive
    },

    UPLOAD_AREA_ACTIVE_LOGOUT(state) {
      state.uploadAreaActive = false
    }
  },
  actions: {},
  getters: {
    getUploadAreaActive: (state) => state.uploadAreaActive
  }
}

export default uploadAreaActive
