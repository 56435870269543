<template>
  <div class="upload-page-container">
    <div
      class="upload-page-left page-container"
      v-if="uploadedImageList.length"
      :style="{
        width: '30%',
      }"
    >
      <div class="uploaded-item" v-for="(item, index) in uploadedImageList" :key="index">
        <ImageCard :image-obj="item" :is-uploaded="true" />
      </div>
    </div>

    <div
      class="upload-page-right page-container"
      :style="{
        width: uploadedImageList.length ? '70%' : '100%',
      }"
    >
      <!-- 上传区域 -->
      <div class="row-item">
        <div class="content-box">
          <UploadArea :image-loading="imageLoading" ref="uploadAreaDom"></UploadArea>
        </div>
      </div>

      <!-- 待上传的图片列表 -->
      <div class="row-item">
        <div class="content-box">
          <ToUploadImageCard ref="toUploadImageCardDom" :loading-all-image="imageLoading"></ToUploadImageCard>
        </div>
      </div>

      <!-- 重置 & 上传 -->
      <div class="row-item">
        <div class="content-box" style="text-align: right">
          <a-button @click="resetUploadInfo" v-if="toUploadImage.list.length">重置</a-button>
          <a-button type="primary" @click="uploadImage">上传</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '@/store'
import router from '@/router'
import ImageCard from '@/components/ImageCard'
import ToUploadImageCard from '@/components/ToUploadImageCard'
import UploadArea from '@/components/UploadArea'
export default {
  components: {
    ImageCard,
    ToUploadImageCard,
    UploadArea,
  },
  data() {
    return {
      imageLoading: false,
    }
  },
  computed: {
    ...mapGetters(['userConfigInfo', 'loggingStatus', 'uploadedImageList', 'toUploadImage']),
  },
  watch: {
    loggingStatus() {
      console.log('watch loggingStatus', this.loggingStatus)
      // 如果退出登录，清空信息
      if (!this.loggingStatus) {
        this.resetUploadInfo()
      }
    },
  },
  methods: {
    uploadImage() {
      console.log('this.userConfigInfo', this.userConfigInfo)
      const { token, selectedRepos, selectedDir } = this.userConfigInfo
      console.log(this.userConfigInfo.token)
      console.log(this.userConfigInfo.selectedRepos)
      console.log(this.userConfigInfo.selectedDir)
      if (!token) {
        this.$message.error('请先完成图床配置！')
        router.push('/setting')
        return
      }

      if (!selectedRepos) {
        this.$message.error('请选择一个仓库！')
        router.push('/setting')
        return
      }

      if (!selectedDir) {
        this.$message.error('目录不能为空！')
        router.push('/setting')
        return
      }

      if (this.toUploadImage.list.length === 0) {
        this.$message.error('图片不能为空！')
        return
      }

      if (this.toUploadImage.list.length === this.toUploadImage.uploadedNumber) {
        this.$message.error('请选择要上传的图片！')
        return
      }

      this.imageLoading = true

      // 执行上传
      this.$refs.toUploadImageCardDom
        .uploadImage_all(this.userConfigInfo)
        .then(v => {
          // eslint-disable-next-line default-case
          switch (v) {
            // 单张图片上传成功
            case 'uploaded':

            // 所有图片上传成功
            // eslint-disable-next-line no-fallthrough
            case 'allUploaded':
              this.imageLoading = false
              store.dispatch('TO_UPLOAD_IMAGE_CLEAN_URL')
              break

            // 上传失败（网络错误等原因）
            case 'uploadFail':
              this.imageLoading = false
              store.dispatch('TO_UPLOAD_IMAGE_LIST_FAIL')
              break
          }
        })
        .catch(e => {
          console.error('upload error: ', e)
          this.imageLoading = false
        })
      console.log('uploadedImageList', this.uploadedImageList)
    },
    resetUploadInfo() {
      this.imageLoading = false
      store.dispatch('TO_UPLOAD_IMAGE_LOGOUT')
    },
  },
}
</script>

<style lang="scss" scoped>
.upload-page-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;

  .upload-page-left {
    height: 100%;
    box-sizing: border-box;
    margin-right: 16px;

    .uploaded-item {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .upload-page-right {
    box-sizing: border-box;
    height: 100%;
    overflow-y: auto;

    .row-item {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 16px;
      box-sizing: border-box;

      &:last-child {
        margin-bottom: 0;
      }

      .content-box {
        width: 100%;
        max-width: 888px;
        margin: 0 auto;
        box-sizing: border-box;
      }
    }

    .upload-status {
      position: relative;
      width: 100%;
      padding: 10px;
      background: #f0f0f0;
      color: #666;
      font-size: 12px;
      box-sizing: border-box;

      .info-item {
        margin-top: 4px;
      }

      .file-status {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .upload-tips {
        display: flex;
        align-items: center;

        i {
          margin-left: 2px;
          font-size: 20px;
        }
      }

      .wait-upload {
        color: #e6a23c;
      }

      .uploading {
        color: #409eff;
      }

      .uploaded {
        color: #67c23a;
      }
    }

    .external-link {
      width: 100%;

      .external-link-input {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }

        .el-input-group__append {
          width: 100px;
          text-align-last: justify;
        }
      }
    }

    .upload-tools {
      width: 100%;

      .repos-dir-info {
        margin-bottom: 20px;
        font-size: 12px;

        .repos-dir-info-item {
          margin-right: 10px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
.page-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 30px;
  background: #fff;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  overflow-y: auto;
}
</style>
