<template>
  <div class="fetch-page-container">
    <div
      class="fetch-page-left page-container"
      v-if="uploadedImageList.length"
      :style="{
        width: '30%',
      }"
    >
      <div class="uploaded-item" v-for="(item, index) in uploadedImageList" :key="index">
        <ImageCard :image-obj="item" :is-uploaded="true" />
      </div>
    </div>

    <div
      class="fetch-page-right page-container"
      :style="{
        width: uploadedImageList.length ? '70%' : '100%',
      }"
    >
      <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="资源链接">
          <a-input v-model="src" />
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button type="primary" @click="onSubmit">点击预览</a-button>
          <a-button type="primary" style="margin-left: 10px" @click="commitFetch">确认提交</a-button>
        </a-form-model-item>
      </a-form-model>
      <div class="image-box">
        <img :src="img" @click="imageView(img)" referrerPolicy="no-referrer" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '@/store'
import { fetchSrc } from '@/api/pic'
import ImageCard from '@/components/ImageCard'
export default {
  components: {
    ImageCard,
  },
  data() {
    return {
      form: {},
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      src: '',
      img: '',
    }
  },
  computed: {
    ...mapGetters(['uploadedImageList']),
  },
  methods: {
    onSubmit() {
      this.img = this.src
    },
    commitFetch() {
      fetchSrc(this.img).then(res => {
        this.uploadSuccess(res.data)
      })
    },
    uploadSuccess(data) {
      const item = {
        uuid: data.key,
        key: data.key,
        urlLink: data.urlLink,
        htmlLink: data.htmlLink,
        mdLink: data.mdLink,
        name: data.now,
        deleting: false,
      }
      // uploadedList 增加图片(本次session上传成功的所有图片)
      store.dispatch('UPLOADED_LIST_ADD', item)
    },
    imageView(image) {
      console.log('imgObj', image)
      store.commit('IMAGE_VIEWER', {
        isShow: true,
        url: image,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.fetch-page-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;

  .fetch-page-left {
    height: 100%;
    box-sizing: border-box;
    margin-right: 16px;

    .uploaded-item {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .fetch-page-right {
    box-sizing: border-box;
    height: 100%;
    overflow-y: auto;

    .row-item {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 16px;
      box-sizing: border-box;

      &:last-child {
        margin-bottom: 0;
      }

      .content-box {
        width: 100%;
        max-width: 888px;
        margin: 0 auto;
        box-sizing: border-box;
      }
    }

    .upload-status {
      position: relative;
      width: 100%;
      padding: 10px;
      background: #f0f0f0;
      color: #666;
      font-size: 12px;
      box-sizing: border-box;

      .info-item {
        margin-top: 4px;
      }

      .file-status {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .upload-tips {
        display: flex;
        align-items: center;

        i {
          margin-left: 2px;
          font-size: 20px;
        }
      }

      .wait-upload {
        color: #e6a23c;
      }

      .uploading {
        color: #409eff;
      }

      .uploaded {
        color: #67c23a;
      }
    }

    .external-link {
      width: 100%;

      .external-link-input {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }

        .el-input-group__append {
          width: 100px;
          text-align-last: justify;
        }
      }
    }

    .upload-tools {
      width: 100%;

      .repos-dir-info {
        margin-bottom: 20px;
        font-size: 12px;

        .repos-dir-info-item {
          margin-right: 10px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
.page-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 30px;
  background: #fff;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  overflow-y: auto;
}
</style>
