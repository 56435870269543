import Vue from 'vue'
import VueRouter from 'vue-router'

import routes from './routes'

import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import {
  getToken
} from '@/utils/auth' // getToken from cookie

NProgress.configure({
  showSpinner: false
}) // NProgress Configuration

Vue.use(VueRouter)


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/* 路由守卫 */
router.beforeEach((to, from, next) => {
  NProgress.start()
  const token = getToken()
  // const token = store.getters.token
  console.log("route-token", token)
  console.log("to.path", to.path)
  if (token) {
    if (to.path === '/login') {
      next({
        path: '/upload'
      })
    } else {
      //TODO 其他权限管理
      next()
    }
  } else {
    /* has no token*/
    if (to.path === '/login') { // 在免登录白名单，直接进入
      next()
    } else {
      next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
      NProgress.done()
    }
  }
  NProgress.done()
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})
export default router
