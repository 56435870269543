export function zerofill(n) {
  return n < 10 ? '0' + n : n
}

export function getYyyyMmDd(now = Date.now()) {
  const _now = new Date(now)
  const yyyy = _now.getFullYear()
  const MM = _now.getMonth() + 1
  const DD = _now.getDate()
  return `${yyyy}${zerofill(MM)}${zerofill(DD)}`
}

export function formatTimestamp(now = Date.now()) {
  const _now = new Date(now)
  let YYYY = _now.getFullYear()
  let MM = _now.getMonth() + 1
  let DD = _now.getDate()
  let hh = _now.getHours()
  let mm = _now.getMinutes()
  let ss = _now.getSeconds()
  return `${YYYY}-${zerofill(MM)}-${zerofill(DD)} ${zerofill(hh)}:${zerofill(mm)}:${zerofill(ss)}`
}

export function getTimestamp() {
  return new Date().getTime();
}
