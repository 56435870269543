import request from '@/utils/request'

export function login(username, password, token, headers) {
  console.log("header", headers)
  return request({
    url: 'auth/login',
    method: 'post',
    headers,
    data: {
      account: username,
      password,
      token
    }
  })
}

export function getInfo() {
  return request({
    url: 'auth/info',
    method: 'get'
  })
}

export function logout() {
  return request({
    url: 'auth/logout',
    method: 'post'
  })
}
