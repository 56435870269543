export const filenameHandle = (filename) => {
  const splitIndex = filename.lastIndexOf('.')
  const name = filename.substr(0, splitIndex)
  const suffix = filename.substr(splitIndex + 1, filename.length)

  return {
    name: name,
    suffix: suffix,
  }
}

export const hashFilenameHandle = (hashFilename) => {
  const splitIndex = hashFilename.indexOf('.')
  return hashFilename.substr(0, splitIndex)
}

export const isImage = (suffix) => {
  return /(png|jpg|gif|jpeg|webp|svg\+xml)$/.test(suffix)
}


export const getFileSize = (size) => {
  return (size / 1024).toFixed(2) + ' KB'
}

// 返回链接地址
export const generateExternalLink = (type, content, config) => {

  const cdnLink = `https://cdn.jsdelivr.net/gh/${config.owner}/${config.selectedRepos}@${config.selectedBranch}/${content['path']}`
  const ghLink = decodeURI(content['download_url'])

  switch (type) {
    case 'gh':
      return ghLink

    case 'md_gh':
      return `![${hashFilenameHandle(content.name)}](${ghLink})`

    case 'cdn':
      return cdnLink

    case 'md_cdn':
      return `![${hashFilenameHandle(content.name)}](${cdnLink})`
  }
}
export function generateLink(name, url, type) {
  switch (type) {
    case 'url':
      return url
    case 'html':
      return `<img src="${url}" alt="${name}" title="${name}"/> `
    case 'md':
      return `![${name}](${url})`
  }
}
