<template>
  <div>
    <span>正在跳转</span>
  </div>
</template>

<script>
import { setToken } from '@/utils/auth'
export default {
  data() {
    return {
      jwtToken: this.$route.query.jwtToken,
    }
  },
  created() {
    console.log('redirect')
    this.saveToken()
    this.$router.push('/admin')
  },
  methods: {
    saveToken() {
      this.$store.commit('SET_TOKEN', this.jwtToken)
      setToken(this.jwtToken, false)
    },
  },
}
</script>

<style></style>
